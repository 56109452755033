/* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: 'Roboto-Light';
  src: url('fonts/Roboto-Light.ttf');
}

.font-robotoLight {
  font-family: 'Roboto-Light', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}

.App { /* Base font style */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 16.0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1f1f1f;
  background-color: #fdfdfd;
}

.App .baseFont * { /* Base font style override for UI framework components */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 16.0px;
}

.App .appBg {
  background-color: #fdfdfd;
}

.App .cardBg {
  background-color: #eaeaea;
}

.App .baseTextBg { /* Base text color as background */
  background-color: #1f1f1f;
}

.App .secondaryTextColor {
  color: #909090;
}

.App .secondaryTextBg {
  background-color: #909090;
}

.App .primaryTextColor {
  color: #bee9ee;
}

.App .primaryBg {
  background-color: #bee9ee;
}

.App .highlightTextColor {
  color: #1f1f1f;
}

.App .highlightBg {
  background-color: #1f1f1f;
}

@media (display-mode: standalone) {
  .App {
    user-select: none;
  }
}

/* Styles to customize the UI framework plugin (MUI, https://muicss.com) */
.mui-btn {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 0;
}
.mui-btn--primary {
    background-color: #bee9ee;
}
.mui-btn--primary:active, .mui-btn--primary:focus, .mui-btn--primary:hover {
    background-color: #c3ebf0;
}
.mui-btn--accent {
    background-color: #1f1f1f;
}
.mui-btn--accent:active, .mui-btn--accent:focus, .mui-btn--accent:hover {
    background-color: #353535;
}
.mui-textfield {  /* Reset UI framework styling that affects layout */
    margin-bottom: 0;
}
.mui-textfield>input, .mui-textfield>textarea {
    font-size: inherit;
}
.mui-checkbox, .mui-radio {  /* Reset UI framework styling that affects layout */
    margin-top: 0;
    margin-bottom: 0;
}
.mui-select {  /* Reset UI framework styling that affects layout */
    padding-top: 0;
    margin-bottom: 0;
}
.mui-btn+.mui-btn {  /* Reset UI framework styling that affects layout */
    margin-left: 0;
}
.mui-btn--fab {  /* Reset UI framework styling that breaks compositing */
    z-index: auto;
}

@media (min-width: 320px) {
  .mui-container {
    max-width: 320px;
  }
}
@media (min-width: 568px) {
  .mui-container {
    max-width: 568px;
  }
}
@media (min-width: 768px) {
  .mui-container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .mui-container {
    max-width: 1024px;
  }
}

@keyframes fadeIn {
    0%   { opacity: 0.0; }
    100% { opacity: 1.0; }
}
@keyframes slideIn_right {
    0%   { transform: translateX(100%); }
    100% { transform: translateX(0); }
}
@keyframes slideIn_bottom {
    0%   { transform: translateY(100%); }
    100% { transform: translateY(0); }
}

/* System font classes */

.App .systemFontRegular {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}
.App .systemFontBold {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-weight: 600;
}
.App .systemFontItalic {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-style: italic;
}
.App .systemFontBoldItalic {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-weight: 600;
  font-style: italic;
}

/* App-wide text styles specified in React Studio */

.App .actionFont {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 15.0px;
  font-weight: 400;
}
.App .actionFont * {
  font-size: 15.0px;
}
.App .headlineFont {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 20.0px;
  font-weight: 600;
}
.App .headlineFont * {
  font-size: 20.0px;
}
.App .navBar {
  background-color: #bee9ee;
  color: #d5d5d5;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 50px;
  min-height: 50px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 18px;
}

.App .navBar a {
  color: #d5d5d5;
}

.App .navBar .title {
  position: absolute;
  width: 100%;
  text-align: center;
  padding: 4px;
}

.App .navBar .backBtn {
  position: absolute;
  padding: 4px;
  margin-left: 14px;
  cursor: pointer;
}

.App .navBar > div {
  margin-top: 7px;
}

.App {
  --tab-bar-height: 54px;
}
@media (display-mode: standalone) {
  .App {
    --tab-bar-height: 90px;
  }
}
.App .tabBar {
  height: var(--tab-bar-height);
  display: flex;
  flex-direction: row;
  pointer-events: auto;
  z-index: 9999;
}
.App .tabBar > * {
  flex: 1 0 auto;
  text-align: center;
  cursor: pointer;
}
.App .tabBar div {
  box-sizing: border-box;
  font-size: 13px; /* default size for tab bar text */
  line-height: 15px;
}
@media (max-width: 767px) {
  .App .tabBar div {
    font-size: 11px; /* small screen size for tab bar text */
    line-height: 13px;
  }
}
.App .tabBar img {
  height: 24px;
  padding-top: 6px;
}
.App .tabBarMenu {
  overflow: hidden;
}
.App .tabBarPageContent {
  overflow: scroll;
  flex: 1;
}
.App .tabBarPageContent > .AppScreen {
  position: relative;
  overflow: scroll;
}
.App .tabBarPageContent > .AppScreen > .background {
  position: fixed;
  left: auto;
  top: auto;
  height: calc(100% - var(--tab-bar-height));
}
.App .tabBarPageContent > .AppScreen > .navBarContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.App .tabBarPageContent > .AppScreen > .navBarContainer > .navBar {
  position: fixed;
  left: auto;
  top: auto;
}
@media (min-width: 568px) {
  .App .navBar {
    height: 50px;
    min-height: 50px;
  }
  .App .navBar > div {
    margin-top: 7px;
  }
}
@media (min-width: 768px) {
  .App .navBar {
    height: 50px;
    min-height: 50px;
  }
  .App .navBar > div {
    margin-top: 7px;
  }
}
@media (min-width: 1024px) {
  .App .navBar {
    height: 50px;
    min-height: 50px;
  }
  .App .navBar > div {
    margin-top: 7px;
  }
}
.AppScreen {
  padding-left: 0;
  padding-right: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: auto;
}

.App .background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.App .layoutFlow {
  position: relative;
  width: 100%;
  pointer-events: none;
}
.App .layoutFlow:before {  /* Prevent margin collapse */
  content: "";
  display: table;
  clear: both;
}
.App .layoutFlow .flowRow {
  position: relative;
  width: 100%;
}

.App .screenFgContainer {
  /* Items in the foreground group within a screen are placed with fixed positioning */
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.App .foreground {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.App ul {
  margin: 0;
  padding: 0;
}
.App li {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
}
.App .hasNestedComps {
  position: relative;
}
.App .hasNestedComps:after {
  content: "";
  display: table;
  clear: both;
}
.App .hasNestedComps .background {
  position: absolute;
}
.App .hasNestedComps .layoutFlow {
  position: relative;
}
.App .bottomAlignedContent {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.App .blockInputOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: auto;
  z-index: 99999999;
}

.App .marker {
  width: 0px;
  height: 0px;
}

.App .drawer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.App .drawerOpen {
  transform: translateX(0%);
  transition: 0.3s;
}

.App .drawerClosedLeft {
  transform: translateX(-100%);
  transition: 0.3s;
}

.App .drawerClosedRight {
  transform: translateX(100%);
  transition: 0.3s;
}

.App .drawerContent {
  position: fixed;
  height: 100%;
  overflow: hidden;
}

.App .drawerContent > * {
  height: 100%;
}

/* This component has a fixed-size layout */
  .ResultsByGroup {
    width: 100%; /* This component was designed using a width of 1200px */
    height: 100%;
  }


  .ResultsByGroup > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 20px;
    margin-left: 1060px;
  }
  .ResultsByGroup > .layoutFlow > .elSpacer > * { 
  width: 20px;
  height: 20px;
  }
  


  .ResultsByGroup > .layoutFlow > .elTitle {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .ResultsByGroup > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  


  .ResultsByGroup > .layoutFlow > .elBarchartresultsByGroup {
    position: relative;
    margin-top: 30px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .ResultsByGroup > .layoutFlow > .elBarchartresultsByGroup > * { 
    width: 100%;
  height: 300px;
  }
  


  .ResultsByGroup > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 20px;
    margin-left: 891px;
  }
  .ResultsByGroup > .layoutFlow > .elSpacer2 > * { 
  width: 20px;
  height: 20px;
  }
  


  .ResultsByGroup > .layoutFlow > .elSpacer3 {
    position: relative;
    margin-top: 0px;
    width: 20px;
    margin-left: 947px;
  }
  .ResultsByGroup > .layoutFlow > .elSpacer3 > * { 
  width: 20px;
  height: 20px;
  }
  

/* This component has a fixed-size layout */
  .QuestionList {
    width: 100%; /* This component was designed using a width of 241px */
    height: 100%;
  }


  .QuestionList > .layoutFlow > .elText {
    line-height: 19px;
    position: relative;
    margin-top: 1px;
    margin-left: 2.0%;
    margin-right: calc(2.0% + 0px);
    width: calc(96.0% - 0px);
  }
  .QuestionList > .layoutFlow > .elText > * { 
    width: 100%;
  }
  

.QuestionList .elHotspot {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



  .QuestionList > .foreground > .elHotspot {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .ResultsByQuestion {
    min-height: 300px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 800px */
    height: 100%;
  }
  .ResultsByQuestion > .background > .containerMinHeight {
    min-height: 300px;
  }

/* This component has a fixed-size layout */
  .ResultsByQuestion {
    min-height: 300px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 800px */
    height: 100%;
  }
  .ResultsByQuestion > .background > .containerMinHeight {
    min-height: 300px;
  }


  .ResultsByQuestion > .foreground > .state1_elBarchartresultsGroup1 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .ResultsByQuestion {
    min-height: 300px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 800px */
    height: 100%;
  }
  .ResultsByQuestion > .background > .containerMinHeight {
    min-height: 300px;
  }


  .ResultsByQuestion > .foreground > .state2_elBarchartresultsGroup2 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .ResultsByQuestion {
    min-height: 300px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 800px */
    height: 100%;
  }
  .ResultsByQuestion > .background > .containerMinHeight {
    min-height: 300px;
  }


  .ResultsByQuestion > .foreground > .state3_elBarchartresultsGroup3 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .ResultsByQuestion {
    min-height: 300px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 800px */
    height: 100%;
  }
  .ResultsByQuestion > .background > .containerMinHeight {
    min-height: 300px;
  }


  .ResultsByQuestion > .foreground > .state4_elBarchartresultsGroup4 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .ResultsByQuestion {
    min-height: 300px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 800px */
    height: 100%;
  }
  .ResultsByQuestion > .background > .containerMinHeight {
    min-height: 300px;
  }


  .ResultsByQuestion > .foreground > .state5_elBarchartresultsGroup5 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .ResultsByQuestion {
    min-height: 300px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 800px */
    height: 100%;
  }
  .ResultsByQuestion > .background > .containerMinHeight {
    min-height: 300px;
  }


  .ResultsByQuestion > .foreground > .state6_elBarchartresultsGroup6 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .ResultsByQuestion {
    min-height: 300px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 800px */
    height: 100%;
  }
  .ResultsByQuestion > .background > .containerMinHeight {
    min-height: 300px;
  }


  .ResultsByQuestion > .foreground > .state7_elBarchartresultsGroup7 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

.EngageScreen .elLogo {
}

.EngageScreen .elLogo.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .EngageScreen > .layoutFlow > .elLogo {
    position: relative;
    margin-top: 20px;
    width: 80px;
    margin-left: 20px;
  }
  .EngageScreen > .layoutFlow > .elLogo > * { 
  width: 80px;
  }
  
  .EngageScreen > .layoutFlow > .elLogo > .background > .containerMinHeight {
    min-height: 40px;
  }

@media (min-width: 568px) {
  .EngageScreen > .layoutFlow > .elLogo {
    position: relative;
    margin-top: 20px;
    width: 80px;
    margin-left: 20px;
  }
  .EngageScreen > .layoutFlow > .elLogo > * { 
  width: 80px;
  }
  
  .EngageScreen > .layoutFlow > .elLogo > .background > .containerMinHeight {
    min-height: 41px;
  }

}
@media (min-width: 768px) {
  .EngageScreen > .layoutFlow > .elLogo {
    position: relative;
    margin-top: 20px;
    width: 79px;
    margin-left: 20px;
  }
  .EngageScreen > .layoutFlow > .elLogo > * { 
  width: 79px;
  }
  
  .EngageScreen > .layoutFlow > .elLogo > .background > .containerMinHeight {
    min-height: 40px;
  }

}
@media (min-width: 1024px) {
  .EngageScreen > .layoutFlow > .elLogo {
    position: relative;
    margin-top: 20px;
    width: 79px;
    margin-left: 20px;
  }
  .EngageScreen > .layoutFlow > .elLogo > * { 
  width: 79px;
  }
  
  .EngageScreen > .layoutFlow > .elLogo > .background > .containerMinHeight {
    min-height: 40px;
  }

}


/* Narrowest size for this element's responsive layout */
  .EngageScreen > .layoutFlow > .elPicker {
    position: relative;
    margin-top: 0px;
    width: 181px;
    margin-left: calc(50.0% - 90px);
  }
  .EngageScreen > .layoutFlow > .elPicker > * { 
  width: 181px;
  }
  
@media (min-width: 568px) {
  .EngageScreen > .layoutFlow > .elPicker {
    position: relative;
    margin-top: 0px;
    width: 181px;
    margin-left: calc(50.0% - 90px);
  }
  .EngageScreen > .layoutFlow > .elPicker > * { 
  width: 181px;
  }
  
}
@media (min-width: 768px) {
  .EngageScreen > .layoutFlow > .elPicker {
    position: relative;
    margin-top: 0px;
    width: 180px;
    margin-left: calc(50.0% - 90px);
  }
  .EngageScreen > .layoutFlow > .elPicker > * { 
  width: 180px;
  }
  
}
@media (min-width: 1024px) {
  .EngageScreen > .layoutFlow > .elPicker {
    position: relative;
    margin-top: 0px;
    width: 180px;
    margin-left: calc(50.0% - 90px);
  }
  .EngageScreen > .layoutFlow > .elPicker > * { 
  width: 180px;
  }
  
}

.EngageScreen .elButtonconnect button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.EngageScreen .elButtonconnect button:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .EngageScreen > .layoutFlow > .elButtonconnect {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .EngageScreen > .layoutFlow > .elButtonconnect > * { 
  width: 60px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .EngageScreen > .layoutFlow > .elButtonconnect {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .EngageScreen > .layoutFlow > .elButtonconnect > * { 
  width: 60px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .EngageScreen > .layoutFlow > .elButtonconnect {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .EngageScreen > .layoutFlow > .elButtonconnect > * { 
  width: 60px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .EngageScreen > .layoutFlow > .elButtonconnect {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .EngageScreen > .layoutFlow > .elButtonconnect > * { 
  width: 60px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .EngageScreen > .layoutFlow > .elButtonLabelconnect {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .EngageScreen > .layoutFlow > .elButtonLabelconnect > * { 
  width: 60px;
  }
  
@media (min-width: 568px) {
  .EngageScreen > .layoutFlow > .elButtonLabelconnect {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .EngageScreen > .layoutFlow > .elButtonLabelconnect > * { 
  width: 60px;
  }
  
}
@media (min-width: 768px) {
  .EngageScreen > .layoutFlow > .elButtonLabelconnect {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .EngageScreen > .layoutFlow > .elButtonLabelconnect > * { 
  width: 60px;
  }
  
}
@media (min-width: 1024px) {
  .EngageScreen > .layoutFlow > .elButtonLabelconnect {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .EngageScreen > .layoutFlow > .elButtonLabelconnect > * { 
  width: 60px;
  }
  
}

.EngageScreen .elButtonengage button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.EngageScreen .elButtonengage button:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .EngageScreen > .layoutFlow > .elButtonengage {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .EngageScreen > .layoutFlow > .elButtonengage > * { 
  width: 60px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .EngageScreen > .layoutFlow > .elButtonengage {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .EngageScreen > .layoutFlow > .elButtonengage > * { 
  width: 60px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .EngageScreen > .layoutFlow > .elButtonengage {
    position: relative;
    margin-top: 39px;
    width: 60px;
    margin-left: 30px;
  }
  .EngageScreen > .layoutFlow > .elButtonengage > * { 
  width: 60px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .EngageScreen > .layoutFlow > .elButtonengage {
    position: relative;
    margin-top: 39px;
    width: 60px;
    margin-left: 30px;
  }
  .EngageScreen > .layoutFlow > .elButtonengage > * { 
  width: 60px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .EngageScreen > .layoutFlow > .elButtonLabelengage {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .EngageScreen > .layoutFlow > .elButtonLabelengage > * { 
  width: 60px;
  }
  
@media (min-width: 568px) {
  .EngageScreen > .layoutFlow > .elButtonLabelengage {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .EngageScreen > .layoutFlow > .elButtonLabelengage > * { 
  width: 60px;
  }
  
}
@media (min-width: 768px) {
  .EngageScreen > .layoutFlow > .elButtonLabelengage {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .EngageScreen > .layoutFlow > .elButtonLabelengage > * { 
  width: 60px;
  }
  
}
@media (min-width: 1024px) {
  .EngageScreen > .layoutFlow > .elButtonLabelengage {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .EngageScreen > .layoutFlow > .elButtonLabelengage > * { 
  width: 60px;
  }
  
}

.EngageScreen .elButtonactivate button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.EngageScreen .elButtonactivate button:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .EngageScreen > .layoutFlow > .elButtonactivate {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .EngageScreen > .layoutFlow > .elButtonactivate > * { 
  width: 60px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .EngageScreen > .layoutFlow > .elButtonactivate {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .EngageScreen > .layoutFlow > .elButtonactivate > * { 
  width: 60px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .EngageScreen > .layoutFlow > .elButtonactivate {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .EngageScreen > .layoutFlow > .elButtonactivate > * { 
  width: 60px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .EngageScreen > .layoutFlow > .elButtonactivate {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .EngageScreen > .layoutFlow > .elButtonactivate > * { 
  width: 60px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .EngageScreen > .layoutFlow > .elButtonLabelactivate {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .EngageScreen > .layoutFlow > .elButtonLabelactivate > * { 
  width: 60px;
  }
  
@media (min-width: 568px) {
  .EngageScreen > .layoutFlow > .elButtonLabelactivate {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .EngageScreen > .layoutFlow > .elButtonLabelactivate > * { 
  width: 60px;
  }
  
}
@media (min-width: 768px) {
  .EngageScreen > .layoutFlow > .elButtonLabelactivate {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .EngageScreen > .layoutFlow > .elButtonLabelactivate > * { 
  width: 60px;
  }
  
}
@media (min-width: 1024px) {
  .EngageScreen > .layoutFlow > .elButtonLabelactivate {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .EngageScreen > .layoutFlow > .elButtonLabelactivate > * { 
  width: 60px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .EngageScreen > .layoutFlow > .elAngledBarchartpopulationByPersonix {
    position: relative;
    margin-top: -227px;
    margin-left: 125px;
    margin-right: calc(5.0% + 0px);
    width: calc(95.0% - 125px);
  }
  .EngageScreen > .layoutFlow > .elAngledBarchartpopulationByPersonix > * { 
    width: 100%;
  height: 300px;
  }
  
@media (min-width: 568px) {
  .EngageScreen > .layoutFlow > .elAngledBarchartpopulationByPersonix {
    position: relative;
    margin-top: -227px;
    margin-left: 125px;
    margin-right: calc(5.0% + 0px);
    width: calc(95.0% - 125px);
  }
  .EngageScreen > .layoutFlow > .elAngledBarchartpopulationByPersonix > * { 
    width: 100%;
  height: 300px;
  }
  
}
@media (min-width: 768px) {
  .EngageScreen > .layoutFlow > .elAngledBarchartpopulationByPersonix {
    position: relative;
    margin-top: -226px;
    margin-left: 124px;
    margin-right: calc(5.0% + 0px);
    width: calc(95.0% - 124px);
  }
  .EngageScreen > .layoutFlow > .elAngledBarchartpopulationByPersonix > * { 
    width: 100%;
  height: 298px;
  }
  
}
@media (min-width: 1024px) {
  .EngageScreen > .layoutFlow > .elAngledBarchartpopulationByPersonix {
    position: relative;
    margin-top: -226px;
    margin-left: 124px;
    margin-right: calc(5.0% + 0px);
    width: calc(95.0% - 124px);
  }
  .EngageScreen > .layoutFlow > .elAngledBarchartpopulationByPersonix > * { 
    width: 100%;
  height: 298px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConnectScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .ConnectScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .ConnectScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .ConnectScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .ConnectScreen > .layoutFlow > .flowRow > .elText {
    overflow: hidden;
    position: absolute;
    margin-top: 25px;
    width: 800px;
    margin-left: calc(50.0% - 400px);
  }
  .ConnectScreen > .layoutFlow > .flowRow > .elText > * { 
  width: 800px;
  }
  
  .flowRow_ConnectScreen_elText_273046 { 
  }
  
@media (min-width: 568px) {
  .ConnectScreen > .layoutFlow > .flowRow > .elText {
    overflow: hidden;
    position: absolute;
    margin-top: 25px;
    width: 800px;
    margin-left: calc(50.0% - 400px);
  }
  .ConnectScreen > .layoutFlow > .flowRow > .elText > * { 
  width: 800px;
  }
  
  .flowRow_ConnectScreen_elText_273046 { 
  }
  
}
@media (min-width: 768px) {
  .ConnectScreen > .layoutFlow > .flowRow > .elText {
    overflow: hidden;
    position: absolute;
    margin-top: 25px;
    width: 795px;
    margin-left: calc(50.0% - 397px);
  }
  .ConnectScreen > .layoutFlow > .flowRow > .elText > * { 
  width: 795px;
  }
  
  .flowRow_ConnectScreen_elText_273046 { 
  }
  
}
@media (min-width: 1024px) {
  .ConnectScreen > .layoutFlow > .flowRow > .elText {
    overflow: hidden;
    position: absolute;
    margin-top: 25px;
    width: 795px;
    margin-left: calc(50.0% - 397px);
  }
  .ConnectScreen > .layoutFlow > .flowRow > .elText > * { 
  width: 795px;
  }
  
  .flowRow_ConnectScreen_elText_273046 { 
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConnectScreen > .layoutFlow > .flowRow > .elPicker {
    position: absolute;
    top: 59px;
    width: 181px;
    margin-left: calc(49.8% - 90px);
  }
  .ConnectScreen > .layoutFlow > .flowRow > .elPicker > * { 
  width: 181px;
  }
  
@media (min-width: 568px) {
  .ConnectScreen > .layoutFlow > .flowRow > .elPicker {
    position: absolute;
    top: 59px;
    width: 181px;
    margin-left: calc(49.9% - 90px);
  }
  .ConnectScreen > .layoutFlow > .flowRow > .elPicker > * { 
  width: 181px;
  }
  
}
@media (min-width: 768px) {
  .ConnectScreen > .layoutFlow > .flowRow > .elPicker {
    position: absolute;
    top: 58px;
    width: 180px;
    margin-left: calc(49.9% - 90px);
  }
  .ConnectScreen > .layoutFlow > .flowRow > .elPicker > * { 
  width: 180px;
  }
  
}
@media (min-width: 1024px) {
  .ConnectScreen > .layoutFlow > .flowRow > .elPicker {
    position: absolute;
    top: 58px;
    width: 180px;
    margin-left: calc(50.0% - 90px);
  }
  .ConnectScreen > .layoutFlow > .flowRow > .elPicker > * { 
  width: 180px;
  }
  
}

.ConnectScreen .elLogo {
}

.ConnectScreen .elLogo.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .ConnectScreen > .layoutFlow > .elLogo {
    position: relative;
    margin-top: 20px;
    width: 80px;
    margin-left: 20px;
  }
  .ConnectScreen > .layoutFlow > .elLogo > * { 
  width: 80px;
  }
  
  .ConnectScreen > .layoutFlow > .elLogo > .background > .containerMinHeight {
    min-height: 40px;
  }

@media (min-width: 568px) {
  .ConnectScreen > .layoutFlow > .elLogo {
    position: relative;
    margin-top: 20px;
    width: 80px;
    margin-left: 20px;
  }
  .ConnectScreen > .layoutFlow > .elLogo > * { 
  width: 80px;
  }
  
  .ConnectScreen > .layoutFlow > .elLogo > .background > .containerMinHeight {
    min-height: 41px;
  }

}
@media (min-width: 768px) {
  .ConnectScreen > .layoutFlow > .elLogo {
    position: relative;
    margin-top: 20px;
    width: 79px;
    margin-left: 20px;
  }
  .ConnectScreen > .layoutFlow > .elLogo > * { 
  width: 79px;
  }
  
  .ConnectScreen > .layoutFlow > .elLogo > .background > .containerMinHeight {
    min-height: 40px;
  }

}
@media (min-width: 1024px) {
  .ConnectScreen > .layoutFlow > .elLogo {
    position: relative;
    margin-top: 20px;
    width: 79px;
    margin-left: 20px;
  }
  .ConnectScreen > .layoutFlow > .elLogo > * { 
  width: 79px;
  }
  
  .ConnectScreen > .layoutFlow > .elLogo > .background > .containerMinHeight {
    min-height: 40px;
  }

}

.ConnectScreen .elButtonconnect button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.ConnectScreen .elButtonconnect button:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .ConnectScreen > .layoutFlow > .elButtonconnect {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .ConnectScreen > .layoutFlow > .elButtonconnect > * { 
  width: 60px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .ConnectScreen > .layoutFlow > .elButtonconnect {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .ConnectScreen > .layoutFlow > .elButtonconnect > * { 
  width: 60px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .ConnectScreen > .layoutFlow > .elButtonconnect {
    position: relative;
    margin-top: 39px;
    width: 60px;
    margin-left: 30px;
  }
  .ConnectScreen > .layoutFlow > .elButtonconnect > * { 
  width: 60px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .ConnectScreen > .layoutFlow > .elButtonconnect {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .ConnectScreen > .layoutFlow > .elButtonconnect > * { 
  width: 60px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConnectScreen > .layoutFlow > .elButtonLabelconnect {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .ConnectScreen > .layoutFlow > .elButtonLabelconnect > * { 
  width: 60px;
  }
  
@media (min-width: 568px) {
  .ConnectScreen > .layoutFlow > .elButtonLabelconnect {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .ConnectScreen > .layoutFlow > .elButtonLabelconnect > * { 
  width: 60px;
  }
  
}
@media (min-width: 768px) {
  .ConnectScreen > .layoutFlow > .elButtonLabelconnect {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .ConnectScreen > .layoutFlow > .elButtonLabelconnect > * { 
  width: 60px;
  }
  
}
@media (min-width: 1024px) {
  .ConnectScreen > .layoutFlow > .elButtonLabelconnect {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .ConnectScreen > .layoutFlow > .elButtonLabelconnect > * { 
  width: 60px;
  }
  
}

.ConnectScreen .elButtonengage button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.ConnectScreen .elButtonengage button:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .ConnectScreen > .layoutFlow > .elButtonengage {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .ConnectScreen > .layoutFlow > .elButtonengage > * { 
  width: 60px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .ConnectScreen > .layoutFlow > .elButtonengage {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .ConnectScreen > .layoutFlow > .elButtonengage > * { 
  width: 60px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .ConnectScreen > .layoutFlow > .elButtonengage {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .ConnectScreen > .layoutFlow > .elButtonengage > * { 
  width: 60px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .ConnectScreen > .layoutFlow > .elButtonengage {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .ConnectScreen > .layoutFlow > .elButtonengage > * { 
  width: 60px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConnectScreen > .layoutFlow > .elButtonLabelengage {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .ConnectScreen > .layoutFlow > .elButtonLabelengage > * { 
  width: 60px;
  }
  
@media (min-width: 568px) {
  .ConnectScreen > .layoutFlow > .elButtonLabelengage {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .ConnectScreen > .layoutFlow > .elButtonLabelengage > * { 
  width: 60px;
  }
  
}
@media (min-width: 768px) {
  .ConnectScreen > .layoutFlow > .elButtonLabelengage {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .ConnectScreen > .layoutFlow > .elButtonLabelengage > * { 
  width: 60px;
  }
  
}
@media (min-width: 1024px) {
  .ConnectScreen > .layoutFlow > .elButtonLabelengage {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .ConnectScreen > .layoutFlow > .elButtonLabelengage > * { 
  width: 60px;
  }
  
}

.ConnectScreen .elButtonactivate button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.ConnectScreen .elButtonactivate button:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .ConnectScreen > .layoutFlow > .elButtonactivate {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .ConnectScreen > .layoutFlow > .elButtonactivate > * { 
  width: 60px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .ConnectScreen > .layoutFlow > .elButtonactivate {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .ConnectScreen > .layoutFlow > .elButtonactivate > * { 
  width: 60px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .ConnectScreen > .layoutFlow > .elButtonactivate {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .ConnectScreen > .layoutFlow > .elButtonactivate > * { 
  width: 60px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .ConnectScreen > .layoutFlow > .elButtonactivate {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .ConnectScreen > .layoutFlow > .elButtonactivate > * { 
  width: 60px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConnectScreen > .layoutFlow > .elButtonLabelactivate {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .ConnectScreen > .layoutFlow > .elButtonLabelactivate > * { 
  width: 60px;
  }
  
@media (min-width: 568px) {
  .ConnectScreen > .layoutFlow > .elButtonLabelactivate {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .ConnectScreen > .layoutFlow > .elButtonLabelactivate > * { 
  width: 60px;
  }
  
}
@media (min-width: 768px) {
  .ConnectScreen > .layoutFlow > .elButtonLabelactivate {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .ConnectScreen > .layoutFlow > .elButtonLabelactivate > * { 
  width: 60px;
  }
  
}
@media (min-width: 1024px) {
  .ConnectScreen > .layoutFlow > .elButtonLabelactivate {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .ConnectScreen > .layoutFlow > .elButtonLabelactivate > * { 
  width: 60px;
  }
  
}

.ConnectScreen .elComponentresultsByQuestionGroup {
}

.ConnectScreen .elComponentresultsByQuestionGroup.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .ConnectScreen > .layoutFlow > .elComponentresultsByQuestionGroup {
    position: relative;
    margin-top: -320px;
    margin-left: 120px;
    margin-right: calc(5.0% + 0px);
    width: calc(95.0% - 120px);
  }
  .ConnectScreen > .layoutFlow > .elComponentresultsByQuestionGroup > * { 
    width: 100%;
  }
  
  .ConnectScreen > .layoutFlow > .elComponentresultsByQuestionGroup > .background > .containerMinHeight {
    min-height: 233px;
  }

@media (min-width: 568px) {
  .ConnectScreen > .layoutFlow > .elComponentresultsByQuestionGroup {
    position: relative;
    margin-top: -320px;
    margin-left: 120px;
    margin-right: calc(5.0% + 0px);
    width: calc(95.0% - 120px);
  }
  .ConnectScreen > .layoutFlow > .elComponentresultsByQuestionGroup > * { 
    width: 100%;
  }
  
  .ConnectScreen > .layoutFlow > .elComponentresultsByQuestionGroup > .background > .containerMinHeight {
    min-height: 232px;
  }

}
@media (min-width: 768px) {
  .ConnectScreen > .layoutFlow > .elComponentresultsByQuestionGroup {
    position: relative;
    margin-top: -318px;
    margin-left: 119px;
    margin-right: calc(5.0% + 0px);
    width: calc(95.0% - 119px);
  }
  .ConnectScreen > .layoutFlow > .elComponentresultsByQuestionGroup > * { 
    width: 100%;
  }
  
  .ConnectScreen > .layoutFlow > .elComponentresultsByQuestionGroup > .background > .containerMinHeight {
    min-height: 232px;
  }

}
@media (min-width: 1024px) {
  .ConnectScreen > .layoutFlow > .elComponentresultsByQuestionGroup {
    position: relative;
    margin-top: -318px;
    margin-left: 119px;
    margin-right: calc(5.0% + 0px);
    width: calc(95.0% - 119px);
  }
  .ConnectScreen > .layoutFlow > .elComponentresultsByQuestionGroup > * { 
    width: 100%;
  }
  
  .ConnectScreen > .layoutFlow > .elComponentresultsByQuestionGroup > .background > .containerMinHeight {
    min-height: 232px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ConnectScreen > .layoutFlow > .elSpacer3 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 848px;
  }
  .ConnectScreen > .layoutFlow > .elSpacer3 > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .ConnectScreen > .layoutFlow > .elSpacer3 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 848px;
  }
  .ConnectScreen > .layoutFlow > .elSpacer3 > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .ConnectScreen > .layoutFlow > .elSpacer3 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 844px;
  }
  .ConnectScreen > .layoutFlow > .elSpacer3 > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .ConnectScreen > .layoutFlow > .elSpacer3 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 844px;
  }
  .ConnectScreen > .layoutFlow > .elSpacer3 > * { 
  width: 10px;
  height: 10px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConnectScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 828px;
  }
  .ConnectScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .ConnectScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 828px;
  }
  .ConnectScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .ConnectScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 822px;
  }
  .ConnectScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .ConnectScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 822px;
  }
  .ConnectScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
}

.ConnectScreen .elComponentresultsByQuestion {
}

.ConnectScreen .elComponentresultsByQuestion.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .ConnectScreen > .layoutFlow > .elComponentresultsByQuestion {
    position: relative;
    margin-top: 0px;
    margin-left: 120px;
    margin-right: calc(5.0% + 0px);
    width: calc(95.0% - 120px);
  }
  .ConnectScreen > .layoutFlow > .elComponentresultsByQuestion > * { 
    width: 100%;
  }
  
  .ConnectScreen > .layoutFlow > .elComponentresultsByQuestion > .background > .containerMinHeight {
    min-height: 100px;
  }

@media (min-width: 568px) {
  .ConnectScreen > .layoutFlow > .elComponentresultsByQuestion {
    position: relative;
    margin-top: 0px;
    margin-left: 120px;
    margin-right: calc(5.0% + 0px);
    width: calc(95.0% - 120px);
  }
  .ConnectScreen > .layoutFlow > .elComponentresultsByQuestion > * { 
    width: 100%;
  }
  
  .ConnectScreen > .layoutFlow > .elComponentresultsByQuestion > .background > .containerMinHeight {
    min-height: 100px;
  }

}
@media (min-width: 768px) {
  .ConnectScreen > .layoutFlow > .elComponentresultsByQuestion {
    position: relative;
    margin-top: 0px;
    margin-left: 119px;
    margin-right: calc(5.0% + 0px);
    width: calc(95.0% - 119px);
  }
  .ConnectScreen > .layoutFlow > .elComponentresultsByQuestion > * { 
    width: 100%;
  }
  
  .ConnectScreen > .layoutFlow > .elComponentresultsByQuestion > .background > .containerMinHeight {
    min-height: 100px;
  }

}
@media (min-width: 1024px) {
  .ConnectScreen > .layoutFlow > .elComponentresultsByQuestion {
    position: relative;
    margin-top: 0px;
    margin-left: 119px;
    margin-right: calc(5.0% + 0px);
    width: calc(95.0% - 119px);
  }
  .ConnectScreen > .layoutFlow > .elComponentresultsByQuestion > * { 
    width: 100%;
  }
  
  .ConnectScreen > .layoutFlow > .elComponentresultsByQuestion > .background > .containerMinHeight {
    min-height: 100px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ConnectScreen > .layoutFlow > .elLabelresultsByQuestion {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    margin-left: 121px;
    margin-right: calc(45.0% + 0px);
    width: calc(55.0% - 121px);
  }
  .ConnectScreen > .layoutFlow > .elLabelresultsByQuestion > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .ConnectScreen > .layoutFlow > .elLabelresultsByQuestion {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    margin-left: 121px;
    margin-right: calc(45.0% + 0px);
    width: calc(55.0% - 121px);
  }
  .ConnectScreen > .layoutFlow > .elLabelresultsByQuestion > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .ConnectScreen > .layoutFlow > .elLabelresultsByQuestion {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    margin-left: 120px;
    margin-right: calc(45.0% + 0px);
    width: calc(55.0% - 120px);
  }
  .ConnectScreen > .layoutFlow > .elLabelresultsByQuestion > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .ConnectScreen > .layoutFlow > .elLabelresultsByQuestion {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    margin-left: 120px;
    margin-right: calc(45.0% + 0px);
    width: calc(55.0% - 120px);
  }
  .ConnectScreen > .layoutFlow > .elLabelresultsByQuestion > * { 
    width: 100%;
  }
  
}

.ConnectScreen .elListresultsByQuestion li {
  margin-bottom: 5px;
}



/* Narrowest size for this element's responsive layout */
  .ConnectScreen > .layoutFlow > .flowRow > .elListresultsByQuestion {
    position: absolute;
    margin-top: 10px;
    margin-left: 121px;
    margin-right: calc(45.0% + 0px);
    width: calc(55.0% - 121px);
  }
  .ConnectScreen > .layoutFlow > .flowRow > .elListresultsByQuestion > * { 
    width: 100%;
  }
  
  .flowRow_ConnectScreen_elListresultsByQuestion_216262 { 
  }
  
@media (min-width: 568px) {
  .ConnectScreen > .layoutFlow > .flowRow > .elListresultsByQuestion {
    position: absolute;
    margin-top: 10px;
    margin-left: 121px;
    margin-right: calc(45.0% + 0px);
    width: calc(55.0% - 121px);
  }
  .ConnectScreen > .layoutFlow > .flowRow > .elListresultsByQuestion > * { 
    width: 100%;
  }
  
  .flowRow_ConnectScreen_elListresultsByQuestion_216262 { 
  }
  
}
@media (min-width: 768px) {
  .ConnectScreen > .layoutFlow > .flowRow > .elListresultsByQuestion {
    position: absolute;
    margin-top: 10px;
    margin-left: 120px;
    margin-right: calc(45.0% + 0px);
    width: calc(55.0% - 120px);
  }
  .ConnectScreen > .layoutFlow > .flowRow > .elListresultsByQuestion > * { 
    width: 100%;
  }
  
  .flowRow_ConnectScreen_elListresultsByQuestion_216262 { 
  }
  
}
@media (min-width: 1024px) {
  .ConnectScreen > .layoutFlow > .flowRow > .elListresultsByQuestion {
    position: absolute;
    margin-top: 10px;
    margin-left: 120px;
    margin-right: calc(45.0% + 0px);
    width: calc(55.0% - 120px);
  }
  .ConnectScreen > .layoutFlow > .flowRow > .elListresultsByQuestion > * { 
    width: 100%;
  }
  
  .flowRow_ConnectScreen_elListresultsByQuestion_216262 { 
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConnectScreen > .layoutFlow > .elPiechartquestionBySupport {
    position: relative;
    margin-top: 10px;
    margin-left: 55.0%;
    margin-right: calc(5.0% + 0px);
    width: calc(40.0% - 0px);
  }
  .ConnectScreen > .layoutFlow > .elPiechartquestionBySupport > * { 
    width: 100%;
  height: 300px;
  }
  
@media (min-width: 568px) {
  .ConnectScreen > .layoutFlow > .elPiechartquestionBySupport {
    position: relative;
    margin-top: 10px;
    margin-left: 55.0%;
    margin-right: calc(5.0% + 0px);
    width: calc(40.0% - 0px);
  }
  .ConnectScreen > .layoutFlow > .elPiechartquestionBySupport > * { 
    width: 100%;
  height: 300px;
  }
  
}
@media (min-width: 768px) {
  .ConnectScreen > .layoutFlow > .elPiechartquestionBySupport {
    position: relative;
    margin-top: 10px;
    margin-left: 55.0%;
    margin-right: calc(5.0% + 0px);
    width: calc(40.0% - 0px);
  }
  .ConnectScreen > .layoutFlow > .elPiechartquestionBySupport > * { 
    width: 100%;
  height: 298px;
  }
  
}
@media (min-width: 1024px) {
  .ConnectScreen > .layoutFlow > .elPiechartquestionBySupport {
    position: relative;
    margin-top: 10px;
    margin-left: 55.0%;
    margin-right: calc(5.0% + 0px);
    width: calc(40.0% - 0px);
  }
  .ConnectScreen > .layoutFlow > .elPiechartquestionBySupport > * { 
    width: 100%;
  height: 298px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConnectScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 1066px;
  }
  .ConnectScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .ConnectScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 1066px;
  }
  .ConnectScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .ConnectScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 1059px;
  }
  .ConnectScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .ConnectScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 1059px;
  }
  .ConnectScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ActivateScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .ActivateScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .ActivateScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .ActivateScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .ActivateScreen > .layoutFlow > .flowRow > .elText {
    overflow: hidden;
    position: absolute;
    margin-top: 25px;
    width: 800px;
    margin-left: calc(50.0% - 400px);
  }
  .ActivateScreen > .layoutFlow > .flowRow > .elText > * { 
  width: 800px;
  }
  
  .flowRow_ActivateScreen_elText_435343 { 
  }
  
@media (min-width: 568px) {
  .ActivateScreen > .layoutFlow > .flowRow > .elText {
    overflow: hidden;
    position: absolute;
    margin-top: 25px;
    width: 800px;
    margin-left: calc(50.0% - 400px);
  }
  .ActivateScreen > .layoutFlow > .flowRow > .elText > * { 
  width: 800px;
  }
  
  .flowRow_ActivateScreen_elText_435343 { 
  }
  
}
@media (min-width: 768px) {
  .ActivateScreen > .layoutFlow > .flowRow > .elText {
    overflow: hidden;
    position: absolute;
    margin-top: 25px;
    width: 795px;
    margin-left: calc(50.0% - 397px);
  }
  .ActivateScreen > .layoutFlow > .flowRow > .elText > * { 
  width: 795px;
  }
  
  .flowRow_ActivateScreen_elText_435343 { 
  }
  
}
@media (min-width: 1024px) {
  .ActivateScreen > .layoutFlow > .flowRow > .elText {
    overflow: hidden;
    position: absolute;
    margin-top: 25px;
    width: 795px;
    margin-left: calc(50.0% - 397px);
  }
  .ActivateScreen > .layoutFlow > .flowRow > .elText > * { 
  width: 795px;
  }
  
  .flowRow_ActivateScreen_elText_435343 { 
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ActivateScreen > .layoutFlow > .flowRow > .elPicker {
    position: absolute;
    top: 59px;
    width: 181px;
    margin-left: calc(49.8% - 90px);
  }
  .ActivateScreen > .layoutFlow > .flowRow > .elPicker > * { 
  width: 181px;
  }
  
@media (min-width: 568px) {
  .ActivateScreen > .layoutFlow > .flowRow > .elPicker {
    position: absolute;
    top: 59px;
    width: 181px;
    margin-left: calc(49.9% - 90px);
  }
  .ActivateScreen > .layoutFlow > .flowRow > .elPicker > * { 
  width: 181px;
  }
  
}
@media (min-width: 768px) {
  .ActivateScreen > .layoutFlow > .flowRow > .elPicker {
    position: absolute;
    top: 58px;
    width: 180px;
    margin-left: calc(49.9% - 90px);
  }
  .ActivateScreen > .layoutFlow > .flowRow > .elPicker > * { 
  width: 180px;
  }
  
}
@media (min-width: 1024px) {
  .ActivateScreen > .layoutFlow > .flowRow > .elPicker {
    position: absolute;
    top: 58px;
    width: 180px;
    margin-left: calc(50.0% - 90px);
  }
  .ActivateScreen > .layoutFlow > .flowRow > .elPicker > * { 
  width: 180px;
  }
  
}

.ActivateScreen .elLogo {
}

.ActivateScreen .elLogo.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .ActivateScreen > .layoutFlow > .elLogo {
    position: relative;
    margin-top: 20px;
    width: 80px;
    margin-left: 20px;
  }
  .ActivateScreen > .layoutFlow > .elLogo > * { 
  width: 80px;
  }
  
  .ActivateScreen > .layoutFlow > .elLogo > .background > .containerMinHeight {
    min-height: 40px;
  }

@media (min-width: 568px) {
  .ActivateScreen > .layoutFlow > .elLogo {
    position: relative;
    margin-top: 20px;
    width: 80px;
    margin-left: 20px;
  }
  .ActivateScreen > .layoutFlow > .elLogo > * { 
  width: 80px;
  }
  
  .ActivateScreen > .layoutFlow > .elLogo > .background > .containerMinHeight {
    min-height: 41px;
  }

}
@media (min-width: 768px) {
  .ActivateScreen > .layoutFlow > .elLogo {
    position: relative;
    margin-top: 20px;
    width: 79px;
    margin-left: 20px;
  }
  .ActivateScreen > .layoutFlow > .elLogo > * { 
  width: 79px;
  }
  
  .ActivateScreen > .layoutFlow > .elLogo > .background > .containerMinHeight {
    min-height: 40px;
  }

}
@media (min-width: 1024px) {
  .ActivateScreen > .layoutFlow > .elLogo {
    position: relative;
    margin-top: 20px;
    width: 79px;
    margin-left: 20px;
  }
  .ActivateScreen > .layoutFlow > .elLogo > * { 
  width: 79px;
  }
  
  .ActivateScreen > .layoutFlow > .elLogo > .background > .containerMinHeight {
    min-height: 40px;
  }

}

.ActivateScreen .elButtonconnect button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.ActivateScreen .elButtonconnect button:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .ActivateScreen > .layoutFlow > .elButtonconnect {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .ActivateScreen > .layoutFlow > .elButtonconnect > * { 
  width: 60px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .ActivateScreen > .layoutFlow > .elButtonconnect {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .ActivateScreen > .layoutFlow > .elButtonconnect > * { 
  width: 60px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .ActivateScreen > .layoutFlow > .elButtonconnect {
    position: relative;
    margin-top: 39px;
    width: 60px;
    margin-left: 30px;
  }
  .ActivateScreen > .layoutFlow > .elButtonconnect > * { 
  width: 60px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .ActivateScreen > .layoutFlow > .elButtonconnect {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .ActivateScreen > .layoutFlow > .elButtonconnect > * { 
  width: 60px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ActivateScreen > .layoutFlow > .elButtonLabelconnect {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .ActivateScreen > .layoutFlow > .elButtonLabelconnect > * { 
  width: 60px;
  }
  
@media (min-width: 568px) {
  .ActivateScreen > .layoutFlow > .elButtonLabelconnect {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .ActivateScreen > .layoutFlow > .elButtonLabelconnect > * { 
  width: 60px;
  }
  
}
@media (min-width: 768px) {
  .ActivateScreen > .layoutFlow > .elButtonLabelconnect {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .ActivateScreen > .layoutFlow > .elButtonLabelconnect > * { 
  width: 60px;
  }
  
}
@media (min-width: 1024px) {
  .ActivateScreen > .layoutFlow > .elButtonLabelconnect {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .ActivateScreen > .layoutFlow > .elButtonLabelconnect > * { 
  width: 60px;
  }
  
}

.ActivateScreen .elButtonengage button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.ActivateScreen .elButtonengage button:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .ActivateScreen > .layoutFlow > .elButtonengage {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .ActivateScreen > .layoutFlow > .elButtonengage > * { 
  width: 60px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .ActivateScreen > .layoutFlow > .elButtonengage {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .ActivateScreen > .layoutFlow > .elButtonengage > * { 
  width: 60px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .ActivateScreen > .layoutFlow > .elButtonengage {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .ActivateScreen > .layoutFlow > .elButtonengage > * { 
  width: 60px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .ActivateScreen > .layoutFlow > .elButtonengage {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .ActivateScreen > .layoutFlow > .elButtonengage > * { 
  width: 60px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ActivateScreen > .layoutFlow > .elButtonLabelengage {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .ActivateScreen > .layoutFlow > .elButtonLabelengage > * { 
  width: 60px;
  }
  
@media (min-width: 568px) {
  .ActivateScreen > .layoutFlow > .elButtonLabelengage {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .ActivateScreen > .layoutFlow > .elButtonLabelengage > * { 
  width: 60px;
  }
  
}
@media (min-width: 768px) {
  .ActivateScreen > .layoutFlow > .elButtonLabelengage {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .ActivateScreen > .layoutFlow > .elButtonLabelengage > * { 
  width: 60px;
  }
  
}
@media (min-width: 1024px) {
  .ActivateScreen > .layoutFlow > .elButtonLabelengage {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .ActivateScreen > .layoutFlow > .elButtonLabelengage > * { 
  width: 60px;
  }
  
}

.ActivateScreen .elButtonactivate button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.ActivateScreen .elButtonactivate button:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .ActivateScreen > .layoutFlow > .elButtonactivate {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .ActivateScreen > .layoutFlow > .elButtonactivate > * { 
  width: 60px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .ActivateScreen > .layoutFlow > .elButtonactivate {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .ActivateScreen > .layoutFlow > .elButtonactivate > * { 
  width: 60px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .ActivateScreen > .layoutFlow > .elButtonactivate {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .ActivateScreen > .layoutFlow > .elButtonactivate > * { 
  width: 60px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .ActivateScreen > .layoutFlow > .elButtonactivate {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .ActivateScreen > .layoutFlow > .elButtonactivate > * { 
  width: 60px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ActivateScreen > .layoutFlow > .elButtonLabelactivate {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .ActivateScreen > .layoutFlow > .elButtonLabelactivate > * { 
  width: 60px;
  }
  
@media (min-width: 568px) {
  .ActivateScreen > .layoutFlow > .elButtonLabelactivate {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .ActivateScreen > .layoutFlow > .elButtonLabelactivate > * { 
  width: 60px;
  }
  
}
@media (min-width: 768px) {
  .ActivateScreen > .layoutFlow > .elButtonLabelactivate {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .ActivateScreen > .layoutFlow > .elButtonLabelactivate > * { 
  width: 60px;
  }
  
}
@media (min-width: 1024px) {
  .ActivateScreen > .layoutFlow > .elButtonLabelactivate {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .ActivateScreen > .layoutFlow > .elButtonLabelactivate > * { 
  width: 60px;
  }
  
}

.ActivateScreen .elComponentresultsByQuestionGroup {
}

.ActivateScreen .elComponentresultsByQuestionGroup.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .ActivateScreen > .layoutFlow > .elComponentresultsByQuestionGroup {
    position: relative;
    margin-top: -320px;
    margin-left: 120px;
    margin-right: calc(5.0% + 0px);
    width: calc(95.0% - 120px);
  }
  .ActivateScreen > .layoutFlow > .elComponentresultsByQuestionGroup > * { 
    width: 100%;
  }
  
  .ActivateScreen > .layoutFlow > .elComponentresultsByQuestionGroup > .background > .containerMinHeight {
    min-height: 233px;
  }

@media (min-width: 568px) {
  .ActivateScreen > .layoutFlow > .elComponentresultsByQuestionGroup {
    position: relative;
    margin-top: -320px;
    margin-left: 120px;
    margin-right: calc(5.0% + 0px);
    width: calc(95.0% - 120px);
  }
  .ActivateScreen > .layoutFlow > .elComponentresultsByQuestionGroup > * { 
    width: 100%;
  }
  
  .ActivateScreen > .layoutFlow > .elComponentresultsByQuestionGroup > .background > .containerMinHeight {
    min-height: 232px;
  }

}
@media (min-width: 768px) {
  .ActivateScreen > .layoutFlow > .elComponentresultsByQuestionGroup {
    position: relative;
    margin-top: -318px;
    margin-left: 119px;
    margin-right: calc(5.0% + 0px);
    width: calc(95.0% - 119px);
  }
  .ActivateScreen > .layoutFlow > .elComponentresultsByQuestionGroup > * { 
    width: 100%;
  }
  
  .ActivateScreen > .layoutFlow > .elComponentresultsByQuestionGroup > .background > .containerMinHeight {
    min-height: 232px;
  }

}
@media (min-width: 1024px) {
  .ActivateScreen > .layoutFlow > .elComponentresultsByQuestionGroup {
    position: relative;
    margin-top: -318px;
    margin-left: 119px;
    margin-right: calc(5.0% + 0px);
    width: calc(95.0% - 119px);
  }
  .ActivateScreen > .layoutFlow > .elComponentresultsByQuestionGroup > * { 
    width: 100%;
  }
  
  .ActivateScreen > .layoutFlow > .elComponentresultsByQuestionGroup > .background > .containerMinHeight {
    min-height: 232px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ActivateScreen > .layoutFlow > .elSpacer3 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 848px;
  }
  .ActivateScreen > .layoutFlow > .elSpacer3 > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .ActivateScreen > .layoutFlow > .elSpacer3 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 848px;
  }
  .ActivateScreen > .layoutFlow > .elSpacer3 > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .ActivateScreen > .layoutFlow > .elSpacer3 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 844px;
  }
  .ActivateScreen > .layoutFlow > .elSpacer3 > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .ActivateScreen > .layoutFlow > .elSpacer3 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 844px;
  }
  .ActivateScreen > .layoutFlow > .elSpacer3 > * { 
  width: 10px;
  height: 10px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ActivateScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 828px;
  }
  .ActivateScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .ActivateScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 828px;
  }
  .ActivateScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .ActivateScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 822px;
  }
  .ActivateScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .ActivateScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 822px;
  }
  .ActivateScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
}

.ActivateScreen .elComponentresultsByQuestion {
}

.ActivateScreen .elComponentresultsByQuestion.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .ActivateScreen > .layoutFlow > .elComponentresultsByQuestion {
    position: relative;
    margin-top: 0px;
    margin-left: 120px;
    margin-right: calc(5.0% + 0px);
    width: calc(95.0% - 120px);
  }
  .ActivateScreen > .layoutFlow > .elComponentresultsByQuestion > * { 
    width: 100%;
  }
  
  .ActivateScreen > .layoutFlow > .elComponentresultsByQuestion > .background > .containerMinHeight {
    min-height: 100px;
  }

@media (min-width: 568px) {
  .ActivateScreen > .layoutFlow > .elComponentresultsByQuestion {
    position: relative;
    margin-top: 0px;
    margin-left: 120px;
    margin-right: calc(5.0% + 0px);
    width: calc(95.0% - 120px);
  }
  .ActivateScreen > .layoutFlow > .elComponentresultsByQuestion > * { 
    width: 100%;
  }
  
  .ActivateScreen > .layoutFlow > .elComponentresultsByQuestion > .background > .containerMinHeight {
    min-height: 100px;
  }

}
@media (min-width: 768px) {
  .ActivateScreen > .layoutFlow > .elComponentresultsByQuestion {
    position: relative;
    margin-top: 0px;
    margin-left: 119px;
    margin-right: calc(5.0% + 0px);
    width: calc(95.0% - 119px);
  }
  .ActivateScreen > .layoutFlow > .elComponentresultsByQuestion > * { 
    width: 100%;
  }
  
  .ActivateScreen > .layoutFlow > .elComponentresultsByQuestion > .background > .containerMinHeight {
    min-height: 100px;
  }

}
@media (min-width: 1024px) {
  .ActivateScreen > .layoutFlow > .elComponentresultsByQuestion {
    position: relative;
    margin-top: 0px;
    margin-left: 119px;
    margin-right: calc(5.0% + 0px);
    width: calc(95.0% - 119px);
  }
  .ActivateScreen > .layoutFlow > .elComponentresultsByQuestion > * { 
    width: 100%;
  }
  
  .ActivateScreen > .layoutFlow > .elComponentresultsByQuestion > .background > .containerMinHeight {
    min-height: 100px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ActivateScreen > .layoutFlow > .elLabelresultsByQuestion {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    margin-left: 121px;
    margin-right: calc(45.0% + 0px);
    width: calc(55.0% - 121px);
  }
  .ActivateScreen > .layoutFlow > .elLabelresultsByQuestion > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .ActivateScreen > .layoutFlow > .elLabelresultsByQuestion {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    margin-left: 121px;
    margin-right: calc(45.0% + 0px);
    width: calc(55.0% - 121px);
  }
  .ActivateScreen > .layoutFlow > .elLabelresultsByQuestion > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .ActivateScreen > .layoutFlow > .elLabelresultsByQuestion {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    margin-left: 120px;
    margin-right: calc(45.0% + 0px);
    width: calc(55.0% - 120px);
  }
  .ActivateScreen > .layoutFlow > .elLabelresultsByQuestion > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .ActivateScreen > .layoutFlow > .elLabelresultsByQuestion {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    margin-left: 120px;
    margin-right: calc(45.0% + 0px);
    width: calc(55.0% - 120px);
  }
  .ActivateScreen > .layoutFlow > .elLabelresultsByQuestion > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ActivateScreen > .layoutFlow > .elText3 {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: calc(75.0% - 119px);
  }
  .ActivateScreen > .layoutFlow > .elText3 > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .ActivateScreen > .layoutFlow > .elText3 {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: calc(75.0% - 119px);
  }
  .ActivateScreen > .layoutFlow > .elText3 > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .ActivateScreen > .layoutFlow > .elText3 {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 236px;
    margin-left: calc(75.0% - 118px);
  }
  .ActivateScreen > .layoutFlow > .elText3 > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .ActivateScreen > .layoutFlow > .elText3 {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 236px;
    margin-left: calc(75.0% - 118px);
  }
  .ActivateScreen > .layoutFlow > .elText3 > * { 
  width: 236px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ActivateScreen > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 1px;
    width: 310px;
    margin-left: calc(75.0% - 155px);
  }
  .ActivateScreen > .layoutFlow > .elText2 > * { 
  width: 310px;
  }
  
@media (min-width: 568px) {
  .ActivateScreen > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 1px;
    width: 310px;
    margin-left: calc(75.0% - 155px);
  }
  .ActivateScreen > .layoutFlow > .elText2 > * { 
  width: 310px;
  }
  
}
@media (min-width: 768px) {
  .ActivateScreen > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 1px;
    width: 308px;
    margin-left: calc(75.0% - 154px);
  }
  .ActivateScreen > .layoutFlow > .elText2 > * { 
  width: 308px;
  }
  
}
@media (min-width: 1024px) {
  .ActivateScreen > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 1px;
    width: 308px;
    margin-left: calc(75.0% - 154px);
  }
  .ActivateScreen > .layoutFlow > .elText2 > * { 
  width: 308px;
  }
  
}

.ActivateScreen .elListresultsByQuestion li {
  margin-bottom: 5px;
}



/* Narrowest size for this element's responsive layout */
  .ActivateScreen > .layoutFlow > .flowRow > .elListresultsByQuestion {
    position: absolute;
    margin-top: 10px;
    margin-left: 121px;
    margin-right: calc(45.0% + 0px);
    width: calc(55.0% - 121px);
  }
  .ActivateScreen > .layoutFlow > .flowRow > .elListresultsByQuestion > * { 
    width: 100%;
  }
  
  .flowRow_ActivateScreen_elListresultsByQuestion_241079 { 
  }
  
@media (min-width: 568px) {
  .ActivateScreen > .layoutFlow > .flowRow > .elListresultsByQuestion {
    position: absolute;
    margin-top: 10px;
    margin-left: 121px;
    margin-right: calc(45.0% + 0px);
    width: calc(55.0% - 121px);
  }
  .ActivateScreen > .layoutFlow > .flowRow > .elListresultsByQuestion > * { 
    width: 100%;
  }
  
  .flowRow_ActivateScreen_elListresultsByQuestion_241079 { 
  }
  
}
@media (min-width: 768px) {
  .ActivateScreen > .layoutFlow > .flowRow > .elListresultsByQuestion {
    position: absolute;
    margin-top: 10px;
    margin-left: 120px;
    margin-right: calc(45.0% + 0px);
    width: calc(55.0% - 120px);
  }
  .ActivateScreen > .layoutFlow > .flowRow > .elListresultsByQuestion > * { 
    width: 100%;
  }
  
  .flowRow_ActivateScreen_elListresultsByQuestion_241079 { 
  }
  
}
@media (min-width: 1024px) {
  .ActivateScreen > .layoutFlow > .flowRow > .elListresultsByQuestion {
    position: absolute;
    margin-top: 10px;
    margin-left: 120px;
    margin-right: calc(45.0% + 0px);
    width: calc(55.0% - 120px);
  }
  .ActivateScreen > .layoutFlow > .flowRow > .elListresultsByQuestion > * { 
    width: 100%;
  }
  
  .flowRow_ActivateScreen_elListresultsByQuestion_241079 { 
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ActivateScreen > .layoutFlow > .elPiechartquestionBySupport {
    position: relative;
    margin-top: 36px;
    margin-left: calc(75.0% - 64px);
    margin-right: calc(5.0% + 0px);
    width: calc(95.0% - 0px);
  }
  .ActivateScreen > .layoutFlow > .elPiechartquestionBySupport > * { 
    width: 100%;
  height: 300px;
  }
  
@media (min-width: 568px) {
  .ActivateScreen > .layoutFlow > .elPiechartquestionBySupport {
    position: relative;
    margin-top: 36px;
    margin-left: calc(75.0% - 113px);
    margin-right: calc(5.0% + 0px);
    width: calc(95.0% - 0px);
  }
  .ActivateScreen > .layoutFlow > .elPiechartquestionBySupport > * { 
    width: 100%;
  height: 300px;
  }
  
}
@media (min-width: 768px) {
  .ActivateScreen > .layoutFlow > .elPiechartquestionBySupport {
    position: relative;
    margin-top: 36px;
    margin-left: calc(75.0% - 153px);
    margin-right: calc(5.0% + 0px);
    width: calc(95.0% - 0px);
  }
  .ActivateScreen > .layoutFlow > .elPiechartquestionBySupport > * { 
    width: 100%;
  height: 298px;
  }
  
}
@media (min-width: 1024px) {
  .ActivateScreen > .layoutFlow > .elPiechartquestionBySupport {
    position: relative;
    margin-top: 36px;
    margin-left: calc(75.0% - 205px);
    margin-right: calc(5.0% + 0px);
    width: calc(95.0% - 0px);
  }
  .ActivateScreen > .layoutFlow > .elPiechartquestionBySupport > * { 
    width: 100%;
  height: 298px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ActivateScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 1066px;
  }
  .ActivateScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .ActivateScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 1066px;
  }
  .ActivateScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .ActivateScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 1059px;
  }
  .ActivateScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .ActivateScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 1059px;
  }
  .ActivateScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}

/* This component has a fixed-size layout */
  .Logo {
    min-height: 80px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 80px */
    height: 100%;
  }
  .Logo > .background > .containerMinHeight {
    min-height: 80px;
  }


  .Logo > .foreground > .elLogocircle {
    position: absolute;
    left: 1px;
    right: 1px;
    top: 1px;
    width: calc(100.0% - 1px - 1px);
    height: calc(100% + 0.0% + -2.0px);
  }


  .Logo > .foreground > .elLogotext {
    overflow: hidden;
    position: absolute;
    left: 0px;
    right: 0px;
    top: calc(-15px + 50.0%);
    width: 100.0%;
    height: 31px;
  }

/* This component has a fixed-size layout */
  .ContentArea {
    width: 100%; /* This component was designed using a width of 1360px */
    height: 100%;
  }


  .ContentArea > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .ContentArea > .background > .elTopbox {
    position: absolute;
    left: 80px;
    right: 80px;
    top: 40px;
    width: calc(100.0% - 80px - 80px);
    height: 80px;
  }


  .ContentArea > .background > .elBottombox {
    position: absolute;
    left: 80px;
    right: 80px;
    top: 200px;
    width: calc(100.0% - 80px - 80px);
    height: 413px;
  }


  .ContentArea > .layoutFlow > .elTitle {
    overflow: hidden;
    position: relative;
    margin-top: 70px;
    width: 238px;
    margin-left: 160px;
  }
  .ContentArea > .layoutFlow > .elTitle > * { 
  width: 238px;
  }
  


  .ContentArea > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 20px;
    margin-left: 412px;
  }
  .ContentArea > .layoutFlow > .elSpacer > * { 
  width: 20px;
  height: 20px;
  }
  


  .ContentArea > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 20px;
    margin-left: 412px;
  }
  .ContentArea > .layoutFlow > .elSpacerCopy > * { 
  width: 20px;
  height: 20px;
  }
  


  .ContentArea > .layoutFlow > .elSpacerCopy2 {
    position: relative;
    margin-top: 0px;
    width: 20px;
    margin-left: 412px;
  }
  .ContentArea > .layoutFlow > .elSpacerCopy2 > * { 
  width: 20px;
  height: 20px;
  }
  


  .ContentArea > .layoutFlow > .elSpacerCopy3 {
    position: relative;
    margin-top: 0px;
    width: 20px;
    margin-left: 412px;
  }
  .ContentArea > .layoutFlow > .elSpacerCopy3 > * { 
  width: 20px;
  height: 20px;
  }
  


  .ContentArea > .layoutFlow > .elSpacerCopy4 {
    position: relative;
    margin-top: 0px;
    width: 20px;
    margin-left: 412px;
  }
  .ContentArea > .layoutFlow > .elSpacerCopy4 > * { 
  width: 20px;
  height: 20px;
  }
  


  .ContentArea > .layoutFlow > .elSpacerCopy5 {
    position: relative;
    margin-top: 0px;
    width: 20px;
    margin-left: 412px;
  }
  .ContentArea > .layoutFlow > .elSpacerCopy5 > * { 
  width: 20px;
  height: 20px;
  }
  


  .ContentArea > .layoutFlow > .elSpacerCopy6 {
    position: relative;
    margin-top: 0px;
    width: 20px;
    margin-left: 412px;
  }
  .ContentArea > .layoutFlow > .elSpacerCopy6 > * { 
  width: 20px;
  height: 20px;
  }
  

.ContentArea .elHotspotconnect button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



  .ContentArea > .layoutFlow > .flowRow > .elHotspotconnect {
    position: absolute;
    margin-top: 0px;
    width: 302px;
    margin-left: calc(22.1% - 151px);
  }
  .ContentArea > .layoutFlow > .flowRow > .elHotspotconnect > * { 
  width: 302px;
  height: 361px;
  }
  
  .flowRow_ContentArea_elHotspotconnect_648960 { 
  }
  

.ContentArea .elHotspotengage button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



  .ContentArea > .layoutFlow > .flowRow > .elHotspotengage {
    position: absolute;
    top: 0px;
    width: 302px;
    margin-left: calc(50.0% - 151px);
  }
  .ContentArea > .layoutFlow > .flowRow > .elHotspotengage > * { 
  width: 302px;
  height: 361px;
  }
  

.ContentArea .elHotspotactivate button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



  .ContentArea > .layoutFlow > .flowRow > .elHotspotactivate {
    position: absolute;
    top: 0px;
    width: 302px;
    margin-left: calc(77.9% - 151px);
  }
  .ContentArea > .layoutFlow > .flowRow > .elHotspotactivate > * { 
  width: 302px;
  height: 361px;
  }
  


  .ContentArea > .layoutFlow > .flowRow > .elButtonBgconnect {
    position: absolute;
    top: 0px;
    width: 300px;
    margin-left: calc(22.1% - 150px);
  }
  .ContentArea > .layoutFlow > .flowRow > .elButtonBgconnect > * { 
  width: 300px;
  height: 300px;
  }
  


  .ContentArea > .layoutFlow > .flowRow > .elButtonBgenagage {
    position: absolute;
    top: 0px;
    width: 300px;
    margin-left: calc(50.0% - 150px);
  }
  .ContentArea > .layoutFlow > .flowRow > .elButtonBgenagage > * { 
  width: 300px;
  height: 300px;
  }
  


  .ContentArea > .layoutFlow > .flowRow > .elButtonBgactivate {
    position: absolute;
    top: 0px;
    width: 300px;
    margin-left: calc(77.9% - 150px);
  }
  .ContentArea > .layoutFlow > .flowRow > .elButtonBgactivate > * { 
  width: 300px;
  height: 300px;
  }
  


  .ContentArea > .layoutFlow > .elSpacerCopy12 {
    position: relative;
    margin-top: 0px;
    width: 20px;
    margin-left: 929px;
  }
  .ContentArea > .layoutFlow > .elSpacerCopy12 > * { 
  width: 20px;
  height: 20px;
  }
  


  .ContentArea > .layoutFlow > .elSpacerCopy8 {
    position: relative;
    margin-top: 0px;
    width: 20px;
    margin-left: 929px;
  }
  .ContentArea > .layoutFlow > .elSpacerCopy8 > * { 
  width: 20px;
  height: 20px;
  }
  


  .ContentArea > .layoutFlow > .elSpacerCopy11 {
    position: relative;
    margin-top: 0px;
    width: 20px;
    margin-left: 929px;
  }
  .ContentArea > .layoutFlow > .elSpacerCopy11 > * { 
  width: 20px;
  height: 20px;
  }
  


  .ContentArea > .layoutFlow > .elSpacerCopy9 {
    position: relative;
    margin-top: 0px;
    width: 20px;
    margin-left: 929px;
  }
  .ContentArea > .layoutFlow > .elSpacerCopy9 > * { 
  width: 20px;
  height: 20px;
  }
  


  .ContentArea > .layoutFlow > .elSpacerCopy10 {
    position: relative;
    margin-top: 0px;
    width: 20px;
    margin-left: 929px;
  }
  .ContentArea > .layoutFlow > .elSpacerCopy10 > * { 
  width: 20px;
  height: 20px;
  }
  

.ContentArea .elButtonconnect button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.ContentArea .elButtonconnect button:focus {
  outline: none;
}



  .ContentArea > .layoutFlow > .flowRow > .elButtonconnect {
    position: absolute;
    margin-top: 0px;
    width: 100px;
    margin-left: calc(22.1% - 50px);
  }
  .ContentArea > .layoutFlow > .flowRow > .elButtonconnect > * { 
  width: 100px;
  height: 100px;
  }
  
  .flowRow_ContentArea_elButtonconnect_851318 { 
  }
  

.ContentArea .elButtonengage button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.ContentArea .elButtonengage button:focus {
  outline: none;
}



  .ContentArea > .layoutFlow > .flowRow > .elButtonengage {
    position: absolute;
    top: 0px;
    width: 100px;
    margin-left: calc(50.0% - 50px);
  }
  .ContentArea > .layoutFlow > .flowRow > .elButtonengage > * { 
  width: 100px;
  height: 100px;
  }
  

.ContentArea .elButtonactivate button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.ContentArea .elButtonactivate button:focus {
  outline: none;
}



  .ContentArea > .layoutFlow > .elButtonactivate {
    position: relative;
    margin-top: 0px;
    width: 100px;
    margin-left: calc(77.9% - 50px);
  }
  .ContentArea > .layoutFlow > .elButtonactivate > * { 
  width: 100px;
  height: 100px;
  }
  


  .ContentArea > .layoutFlow > .flowRow > .elRadioButtonLabelconnect {
    overflow: hidden;
    position: absolute;
    margin-top: 140px;
    width: 200px;
    margin-left: calc(22.1% - 100px);
  }
  .ContentArea > .layoutFlow > .flowRow > .elRadioButtonLabelconnect > * { 
  width: 200px;
  }
  
  .flowRow_ContentArea_elRadioButtonLabelconnect_725227 { 
  }
  


  .ContentArea > .layoutFlow > .flowRow > .elRadioButtonLabelengage {
    overflow: hidden;
    position: absolute;
    top: 140px;
    width: 200px;
    margin-left: calc(50.0% - 100px);
  }
  .ContentArea > .layoutFlow > .flowRow > .elRadioButtonLabelengage > * { 
  width: 200px;
  }
  


  .ContentArea > .layoutFlow > .elRadioButtonLabelactivate {
    overflow: hidden;
    position: relative;
    margin-top: 140px;
    width: 200px;
    margin-left: calc(77.9% - 100px);
  }
  .ContentArea > .layoutFlow > .elRadioButtonLabelactivate > * { 
  width: 200px;
  }
  


  .ContentArea > .layoutFlow > .elSpacerCopy7 {
    position: relative;
    margin-top: 0px;
    width: 20px;
    margin-left: 412px;
  }
  .ContentArea > .layoutFlow > .elSpacerCopy7 > * { 
  width: 20px;
  height: 20px;
  }
  


  .ContentArea > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 20px;
    margin-left: 929px;
  }
  .ContentArea > .layoutFlow > .elSpacer2 > * { 
  width: 20px;
  height: 20px;
  }
  


  .ContentArea > .layoutFlow > .elSpacerCopy13 {
    position: relative;
    margin-top: 0px;
    width: 20px;
    margin-left: 929px;
  }
  .ContentArea > .layoutFlow > .elSpacerCopy13 > * { 
  width: 20px;
  height: 20px;
  }
  

.ContentArea .elGoToActivate {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



  .ContentArea > .foreground > .elGoToActivate {
    position: absolute;
    left: 906px;
    top: 231px;
    width: 298px;
    height: 307px;
  }

.ContentArea .elGoToEngage {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



  .ContentArea > .foreground > .elGoToEngage {
    position: absolute;
    left: 522px;
    top: 231px;
    width: 298px;
    height: 307px;
  }

.ContentArea .elGoToConnect {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



  .ContentArea > .foreground > .elGoToConnect {
    position: absolute;
    left: 149px;
    top: 231px;
    width: 298px;
    height: 307px;
  }


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .HomeScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .HomeScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .HomeScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.HomeScreen .elLogo {
}

.HomeScreen .elLogo.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elLogo {
    position: relative;
    margin-top: 20px;
    width: 80px;
    margin-left: 20px;
  }
  .HomeScreen > .layoutFlow > .elLogo > * { 
  width: 80px;
  }
  
  .HomeScreen > .layoutFlow > .elLogo > .background > .containerMinHeight {
    min-height: 40px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elLogo {
    position: relative;
    margin-top: 20px;
    width: 80px;
    margin-left: 20px;
  }
  .HomeScreen > .layoutFlow > .elLogo > * { 
  width: 80px;
  }
  
  .HomeScreen > .layoutFlow > .elLogo > .background > .containerMinHeight {
    min-height: 41px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elLogo {
    position: relative;
    margin-top: 20px;
    width: 79px;
    margin-left: 20px;
  }
  .HomeScreen > .layoutFlow > .elLogo > * { 
  width: 79px;
  }
  
  .HomeScreen > .layoutFlow > .elLogo > .background > .containerMinHeight {
    min-height: 40px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elLogo {
    position: relative;
    margin-top: 20px;
    width: 79px;
    margin-left: 20px;
  }
  .HomeScreen > .layoutFlow > .elLogo > * { 
  width: 79px;
  }
  
  .HomeScreen > .layoutFlow > .elLogo > .background > .containerMinHeight {
    min-height: 40px;
  }

}

.HomeScreen .elButtonconnect button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.HomeScreen .elButtonconnect button:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elButtonconnect {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .HomeScreen > .layoutFlow > .elButtonconnect > * { 
  width: 60px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elButtonconnect {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .HomeScreen > .layoutFlow > .elButtonconnect > * { 
  width: 60px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elButtonconnect {
    position: relative;
    margin-top: 39px;
    width: 60px;
    margin-left: 30px;
  }
  .HomeScreen > .layoutFlow > .elButtonconnect > * { 
  width: 60px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elButtonconnect {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .HomeScreen > .layoutFlow > .elButtonconnect > * { 
  width: 60px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elButtonLabelconnect {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .HomeScreen > .layoutFlow > .elButtonLabelconnect > * { 
  width: 60px;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elButtonLabelconnect {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .HomeScreen > .layoutFlow > .elButtonLabelconnect > * { 
  width: 60px;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elButtonLabelconnect {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .HomeScreen > .layoutFlow > .elButtonLabelconnect > * { 
  width: 60px;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elButtonLabelconnect {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .HomeScreen > .layoutFlow > .elButtonLabelconnect > * { 
  width: 60px;
  }
  
}

.HomeScreen .elButtonengage button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.HomeScreen .elButtonengage button:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elButtonengage {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .HomeScreen > .layoutFlow > .elButtonengage > * { 
  width: 60px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elButtonengage {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .HomeScreen > .layoutFlow > .elButtonengage > * { 
  width: 60px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elButtonengage {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .HomeScreen > .layoutFlow > .elButtonengage > * { 
  width: 60px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elButtonengage {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .HomeScreen > .layoutFlow > .elButtonengage > * { 
  width: 60px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elButtonLabelengage {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .HomeScreen > .layoutFlow > .elButtonLabelengage > * { 
  width: 60px;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elButtonLabelengage {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .HomeScreen > .layoutFlow > .elButtonLabelengage > * { 
  width: 60px;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elButtonLabelengage {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .HomeScreen > .layoutFlow > .elButtonLabelengage > * { 
  width: 60px;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elButtonLabelengage {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .HomeScreen > .layoutFlow > .elButtonLabelengage > * { 
  width: 60px;
  }
  
}

.HomeScreen .elButtonactivate button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.HomeScreen .elButtonactivate button:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elButtonactivate {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .HomeScreen > .layoutFlow > .elButtonactivate > * { 
  width: 60px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elButtonactivate {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .HomeScreen > .layoutFlow > .elButtonactivate > * { 
  width: 60px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elButtonactivate {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .HomeScreen > .layoutFlow > .elButtonactivate > * { 
  width: 60px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elButtonactivate {
    position: relative;
    margin-top: 40px;
    width: 60px;
    margin-left: 30px;
  }
  .HomeScreen > .layoutFlow > .elButtonactivate > * { 
  width: 60px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elButtonLabelactivate {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .HomeScreen > .layoutFlow > .elButtonLabelactivate > * { 
  width: 60px;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elButtonLabelactivate {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .HomeScreen > .layoutFlow > .elButtonLabelactivate > * { 
  width: 60px;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elButtonLabelactivate {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .HomeScreen > .layoutFlow > .elButtonLabelactivate > * { 
  width: 60px;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elButtonLabelactivate {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 60px;
    margin-left: 30px;
  }
  .HomeScreen > .layoutFlow > .elButtonLabelactivate > * { 
  width: 60px;
  }
  
}

.HomeScreen .elCompcontentArea {
}

.HomeScreen .elCompcontentArea.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elCompcontentArea {
    position: relative;
    margin-top: -320px;
    margin-left: 120px;
    margin-right: calc(5.0% + 0px);
    width: calc(95.0% - 120px);
  }
  .HomeScreen > .layoutFlow > .elCompcontentArea > * { 
    width: 100%;
  }
  
  .HomeScreen > .layoutFlow > .elCompcontentArea > .background > .containerMinHeight {
    min-height: 330px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elCompcontentArea {
    position: relative;
    margin-top: -320px;
    margin-left: 120px;
    margin-right: calc(5.0% + 0px);
    width: calc(95.0% - 120px);
  }
  .HomeScreen > .layoutFlow > .elCompcontentArea > * { 
    width: 100%;
  }
  
  .HomeScreen > .layoutFlow > .elCompcontentArea > .background > .containerMinHeight {
    min-height: 330px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elCompcontentArea {
    position: relative;
    margin-top: -318px;
    margin-left: 119px;
    margin-right: calc(5.0% + 0px);
    width: calc(95.0% - 119px);
  }
  .HomeScreen > .layoutFlow > .elCompcontentArea > * { 
    width: 100%;
  }
  
  .HomeScreen > .layoutFlow > .elCompcontentArea > .background > .containerMinHeight {
    min-height: 330px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elCompcontentArea {
    position: relative;
    margin-top: -318px;
    margin-left: 119px;
    margin-right: calc(5.0% + 0px);
    width: calc(95.0% - 119px);
  }
  .HomeScreen > .layoutFlow > .elCompcontentArea > * { 
    width: 100%;
  }
  
  .HomeScreen > .layoutFlow > .elCompcontentArea > .background > .containerMinHeight {
    min-height: 330px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 430px;
  }
  .HomeScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 430px;
  }
  .HomeScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 427px;
  }
  .HomeScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 427px;
  }
  .HomeScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 1168px;
  }
  .HomeScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 1168px;
  }
  .HomeScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 1160px;
  }
  .HomeScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 1160px;
  }
  .HomeScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 10px;
  }
  
}

